/**
 * a meshline for a border corner
 */

AFRAME.registerComponent("border-corner", {
    schema: {
        startPoint: {type: "vec2"},
        curveStartPoint: {type: "vec2"},
        curveControlPoint0: {type: "vec2"},
        curveControlPoint1: {type: "vec2"},
        curveEndPoint: {type: "vec2"},
        endPoint: {type: "vec2"},
        curveNumPoints: {default: 8},

        color: {type: "color", default: "#fff"},
        opacity: {type: "number", default: 1},
    },

    init: function () {
        // meshline is buggy, it requires path to be set (we will override path in `this.setPoints`)
        this.el.setAttribute("meshline", {
            path: "0 0 0, 1 0 1",
            color: this.data.color,
            sizeAttenuation: "1",
            lineWidth: "0.01",
        });

        this.setPoints();

        // this.el.components.material.material.depthTest = false;

        // hook into meshline update and change material depthTest
        // after it sets the material
        const thisData = this.data;
        const origMeshlineUpdate = this.el.components.meshline.update;
        this.el.components.meshline.update = function () {
            origMeshlineUpdate.bind(this.el.components.meshline)();

            const material = this.el.getObject3D("mesh").material;
            material.depthTest = false;

            material.opacity = thisData.opacity;
            material.transparent = this.data.opacity <= 0.99;
        };
    },

    update: function (oldData) {
        const modifiedData = AFRAME.utils.diff(oldData, this.data);

        for (const modifiedKey in modifiedData) {
            switch (modifiedKey) {
                case "color":
                    this.el.setAttribute("meshline", "color", this.data.color);
                    break;

                case "opacity":
                    const opacity = this.data.opacity;
                    const material = this.el.getObject3D("mesh").material;
                    material.opacity = opacity;
                    material.transparent = opacity <= 0.99;
                    break;
            }
        }
    },

    setPoints: function () {
        const curve = new THREE.CubicBezierCurve(
            this.data.curveStartPoint,
            this.data.curveControlPoint0,
            this.data.curveControlPoint1,
            this.data.curveEndPoint
        );

        let curvePoints = curve.getPoints(this.data.curveNumPoints);

        curvePoints.unshift(this.data.startPoint);
        curvePoints.push(this.data.endPoint);

        const path = curvePoints.map((point) => `${point.x} ${point.y} 0`).join(", ");

        this.el.setAttribute("meshline", "path", path);
    },
});
